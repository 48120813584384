import {
    Scene,
    WebGLRenderTarget,
    LinearFilter,
    RGBAFormat,
    Color,
    ShaderMaterial,
    Points,
    BufferGeometry,
    PointsMaterial, RenderTarget, Material, InstancedMesh, Matrix4
} from "three";
import { EffectPass } from "./EffectPass.js";
import {DisplayGroup, TypedRanges} from "./DisplayGroup.js";
import { OutlineEffectMaterial } from "./OutlineEffectMaterial.js";
import { GeometryObject3D } from "../Model.js";
import {Web3DPointsMaterial} from "./Web3DPointsMaterial.js";
import {Web3DLineMaterial} from "./Web3DLineMaterial.js";
import {MeshLineGeometry} from "../CustomObjects/MeshLineGeometry.js";
import {iterateTypedRanges} from "../Helpers/common-utils.js";
import {MeshPointsGeometry} from "../CustomObjects/MeshPointsGeometry.js";
import {Web3DMeshPointsMaterial} from "./Web3DMeshPointsMaterial.js";
import {Settings} from "../common.js";

export abstract class SelectionEffectPass extends EffectPass {
    protected selectionScene: Scene;
    protected renderTargetMask: RenderTarget;
    protected renderTargetOutline: RenderTarget;
    protected outlinePass: EffectPass;
    protected shouldClear: boolean;
    protected maskColor = new Color(0x000000);
    protected edgeStrength = 5.0;
    protected fillStrength = 0.1;
    protected abstract maskMaterial: Material;

    constructor(material: ShaderMaterial, settings: Settings) {
        super(material);

        this.selectionScene = new Scene();
        this.shouldClear = true;

        this.renderTargetMask = new RenderTarget(1, 1, {
            minFilter: LinearFilter,
            magFilter: LinearFilter,
            format: RGBAFormat,
            samples: settings.msaa ? 4 : 0
        }) as WebGLRenderTarget;

        this.renderTargetOutline = new RenderTarget(1, 1, {
            minFilter: LinearFilter,
            magFilter: LinearFilter,
            stencilBuffer: false,
            depthBuffer: false,
            format: RGBAFormat,
        });
    }

    addObject(modelId: string, id: number | string, object: GeometryObject3D): SelectionEffectPass {
        this.addClone(modelId, id, object);
        return this;
    }

    addObjectGroups(modelId: string, id: number | string, object: GeometryObject3D, groups: DisplayGroup[] | TypedRanges = [{start: 0, count: Infinity}]): SelectionEffectPass {
        const cloned = this.addClone(modelId, id, object);
        const geom = cloned.geometry;
        if (!Array.isArray(cloned.material))
            cloned.material = [cloned.material];
        geom.clearGroups();
        if (Array.isArray(groups)) {
            for (const group of groups)
                geom.addGroup(group.start, group.count, 0);
        } else {
            iterateTypedRanges(groups, (start, count) => {
                geom.addGroup(start, count, 0);
            });
        }
        return this;
    }

    // Used by ADMS DiagramPlugin
    addObjectInstances(modelId: string, id: number | string, object: InstancedMesh, instanceIds?: number[]): SelectionEffectPass {
        const cloned = this.addClone(modelId, id, object) as InstancedMesh;
        if (Array.isArray(instanceIds) && cloned.isInstancedMesh && object.isInstancedMesh) {
            cloned.count = instanceIds.length;
            const m = new Matrix4();
            for (let i = 0; i < instanceIds.length; i++) {
                object.getMatrixAt(instanceIds[i], m);
                cloned.setMatrixAt(i, m);
            }
        }
        return this;
    }

    private addClone(modelId: string, id: number | string, object: GeometryObject3D): GeometryObject3D {
        let cloned = this.selectionScene.getObjectByName(modelId + id.toString()) as GeometryObject3D;
        const isLine = (object.geometry as MeshLineGeometry).isMeshLineGeometry;
        const isPoint = (object.geometry as MeshPointsGeometry).isMeshPointGeometry;
        if (!cloned) {
            cloned = object.clone(false) as GeometryObject3D;
            cloned.name = modelId + id.toString();
            cloned.matrixAutoUpdate = false;
            cloned.matrix = object.matrixWorld;
            cloned.geometry = isLine ? new MeshLineGeometry() : isPoint ? new MeshPointsGeometry() : new BufferGeometry();

            if (object instanceof Points || isPoint || isLine) {
                // clone material instead of using maskMaterial, to make point selection repeat the shape of the icon texture and line widths be the same
                const origM = Array.isArray(object.material) ?  object.material[0] : object.material;
                const m = origM.clone() as Web3DPointsMaterial | Web3DMeshPointsMaterial | PointsMaterial | Web3DLineMaterial;
                m.opacity = 1;
                m.transparent = false;
                m.color = this.maskColor;
                cloned.material = m;
            }
            else {
                cloned.material = this.maskMaterial;
            }
            this.selectionScene.add(cloned);
        }

        const geom = cloned.geometry;
        const g = object.geometry;
        geom.boundingBox = g.boundingBox;
        geom.boundingSphere = g.boundingSphere;
        geom.attributes = g.attributes;
        geom.setIndex(g.getIndex());
        return cloned;
    }

    removeObject(modelId: string, id: number | string): SelectionEffectPass {
        const cloned = this.selectionScene.getObjectByName(modelId + id.toString());
        this.selectionScene.remove(cloned);
        if (this.selectionScene.children.length === 0)
            this.shouldClear = true;
        return this;
    }

    override setSize(width: number, height: number): void {
        this.renderTargetMask.setSize(width, height);

        const w = width / window.devicePixelRatio;
        const h = height / window.devicePixelRatio;
        this.renderTargetOutline.setSize(w, h);
        (this.outlinePass.getFullscreenMaterial() as OutlineEffectMaterial).updatePixelRatio();
        this.shouldClear = true;
    }
}
