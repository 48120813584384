import {GPUPicker} from "../../Picker/GPUPicker.js";
import {RenderingManager} from "../RenderingManager.js";
import {Web3DCamera} from "../Web3DCamera.js";
import {SettingsDispatcher} from "../../SettingsDispatcher.js";
import {Settings} from "../../common.js";
import {
    DoubleSide, FloatType,
    Mesh,
    OrthographicCamera,
    PlaneGeometry,
    RenderTarget,
    Scene,
    Vector2, Vector3,
    WebGPUCoordinateSystem
} from "three";
import MeshBasicNodeMaterial from "three/examples/jsm/nodes/materials/MeshBasicNodeMaterial.js";
import * as Nodes from "three/examples/jsm/nodes/Nodes.js";

export class WebGPUGPUPicker extends GPUPicker {

    constructor(
        renderingManager: RenderingManager,
        private camera: Web3DCamera,
        private container: HTMLElement,
        settingsDispatcher: SettingsDispatcher<Settings>,
    ) {
        super(renderingManager, settingsDispatcher);
    }

    /**
     * Pick the GPU depth buffer.
     * @param screenPosition The 2D screen coordinate to be picked
     * @returns A 3D vector representing the world space point of the pick
     */
    pick = (() => {
        const viewSize = new Vector2();
        const viewPosition = new Vector2();

        return async (screenPosition: Vector2): Promise<Vector3 | undefined> => {
            this.renderingManager.renderer.getSize(viewSize);
            const dimensions = this.container.getBoundingClientRect();
            const tolerance = this.settingsDispatcher.settings.navigationSnapDistance || 0;

            if (this.container.clientWidth === 0 || this.container.clientHeight === 0) return undefined;
            viewPosition.set(
                (screenPosition.x - dimensions.left) / this.container.clientWidth,
                (screenPosition.y - dimensions.top) / this.container.clientHeight
            );

            // TODO: Tolerance
            // Simple, single-pixel depth sample:
            const depth = await this.pickDepth(viewPosition);
            if (depth === undefined) return undefined;
            return GPUPicker.viewToWorldPoint(viewPosition, depth, this.camera);
        };
    })();

    pickDepth = (() => {
        const cameraFX = new OrthographicCamera(-1, 1, 1, -1, -1, 1);
        cameraFX.coordinateSystem = WebGPUCoordinateSystem;
        cameraFX.updateProjectionMatrix();
        const sceneFX = new Scene();
        const geometryFX = new PlaneGeometry(2, 2);
        const materialFX = new MeshBasicNodeMaterial();
        materialFX.side = DoubleSide;
        const quad = new Mesh(geometryFX, materialFX);
        quad.scale.set(1,-1,1);
        sceneFX.add(quad);
        const singlePixelRT = new RenderTarget(16, 16, {type: FloatType});

        return async (viewPosition: Vector2): Promise<number | undefined> => {
            // TODO: Update uniforms rather than recompiling entire shader
            // TODO: Combine some of the code with WebGL side?

            const renderer = this.renderingManager.renderer as any; // WebGPURenderer
            const depthTexture = this.renderingManager.composer.depthTexture;

            materialFX.colorNode = Nodes.texture(depthTexture, Nodes.vec2(new Vector2(viewPosition.x, viewPosition.y)));
            materialFX.needsUpdate = true;
            //materialFX.uniformsNeedUpdate = true;

            renderer.setRenderTarget(singlePixelRT);
            //renderer.clear();
            renderer.render(sceneFX, cameraFX);

            const buffer = await renderer.readRenderTargetPixelsAsync(singlePixelRT, 0, 0, 16, 16);
            const depth = buffer[0];
            if (depth === 1) return undefined;
            return (depth + 1) / 2; // Depth correction: WebGPU in Chrome returns depth buffer values in [-1,1]
        };
    })();
}
