
// A class that allows systematic, conditional string replacements within larger strings.
// Meant for editing generated shader code.
export class StringReplacer {

    private replacements: Replacement[] = [];

    add(condition: string, key: string, value: string): void {
        this.replacements.push({condition, key, value});
    }

    replace(text: string): string {
        return this.replacements.reduce((t: string, r: Replacement) => t.indexOf(r.condition) !== -1 ? t.replace(r.key, r.value) : t, text);
    }
}

interface Replacement {
    condition: string;
    key: string;
    value: string;
}
