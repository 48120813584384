import {
    WebGLRenderer,
    RenderTarget,
    Color,
    DoubleSide,
    WebGLRenderTarget,
} from "three";
import {EffectPass} from "./../EffectPass.js";
import {Web3DCamera} from "./../Web3DCamera.js";
import MeshBasicNodeMaterial from "three/examples/jsm/nodes/materials/MeshBasicNodeMaterial.js";
import * as Nodes from "three/examples/jsm/nodes/Nodes.js";
import {OutlineEffectNodeMaterial} from "./OutlineEffectNodeMaterial.js";
import {SelectionEffectPass} from "../SelectionEffectPass.js";
import {Settings} from "../../common.js";

export class WebGPUSelectionEffectPass extends SelectionEffectPass {
    maskMaterial: MeshBasicNodeMaterial;
    private readonly mainCamera: Web3DCamera;

    constructor(camera: Web3DCamera, settings: Settings) {

        // This reference is necessary to initialise things properly. In particular, wgslFn.call(), which is injected into a
        // Proxy object in another source file. TODO: Find a better solution or wait for threejs updates.
        (window as any).___nodes = Nodes;
        delete (window as any).___nodes;

        const material = new MeshBasicNodeMaterial();
        super(material, settings);

        const uniforms = [
            // @ts-ignore
            Nodes.uniform(Nodes.color(settings.selectionColor)).label('color'),
            // @ts-ignore
            Nodes.uniform(Nodes.float(this.fillStrength)).label('fillStrength'),
            // @ts-ignore
            Nodes.uniform(Nodes.float(this.edgeStrength)).label('edgeStrength'),
        ];

        // language=wgsl
        const calculateColorNode = (Nodes as any).wgslFn(`
             fn calculateColor(uv: vec2<f32>, maskTexture: texture_2d<f32>, maskTexture_sampler: sampler, outlineTexture: texture_2d<f32>, outlineTexture_sampler: sampler) -> vec4<f32> {
                var correctedUv: vec2<f32> = vec2(uv.x, 1.0 - uv.y);
                var edge: f32 = textureSample(outlineTexture, outlineTexture_sampler, correctedUv).r;
                var mask: f32 = textureSample(maskTexture, maskTexture_sampler, correctedUv).r;
                
                edge = clamp(edge * NodeUniforms.edgeStrength * mask, 0.0, 1.0);
                mask = (1.0 - mask) * NodeUniforms.fillStrength;
                return vec4<f32>(NodeUniforms.color, edge + mask);
            }
        `, uniforms);

        const colorNodeParams = {
            uv: Nodes.uv(),
            maskTexture: Nodes.texture(this.renderTargetMask.texture),
            maskTexture_sampler: Nodes.texture(this.renderTargetMask.texture),
            outlineTexture: Nodes.texture(this.renderTargetOutline.texture),
            outlineTexture_sampler: Nodes.texture(this.renderTargetOutline.texture),
        };

        material.colorNode = calculateColorNode(colorNodeParams);
        material.transparent = true;
        material.depthTest = false;
        material.depthWrite = false;
        material.needsUpdate = true;

        this.mainCamera = camera;
        this.maskMaterial = new MeshBasicNodeMaterial();
        this.maskMaterial.colorNode = Nodes.color(this.maskColor);
        this.maskMaterial.side = DoubleSide;
        this.outlinePass = new EffectPass(new OutlineEffectNodeMaterial(this.renderTargetMask.texture));
    }

    override render(renderer: WebGLRenderer, writeBuffer: RenderTarget): void {
        if (this.selectionScene.children.length > 0 || this.shouldClear) {

            // Render selection mask:
            renderer.setRenderTarget(this.renderTargetMask as any);
            renderer.setClearColor(0xffffff, 1);
            renderer.clear();
            renderer.render(this.selectionScene, this.mainCamera);

            // Render selection outline:
            this.outlinePass.render(renderer, this.renderTargetOutline as WebGLRenderTarget);

            // Render both onto writeBuffer:
            renderer.autoClear = false;
            renderer.autoClearColor = false;
            renderer.autoClearDepth = false;
            renderer.setRenderTarget(writeBuffer as any);
            renderer.render(this.scene, this.camera);

            this.shouldClear = false;
        }
    }
}
