import {BehaviorSubject} from "rxjs";
import {Box3, Matrix4, Mesh, Object3D, Points} from "three";
import {IIntersection} from "./Picker/IIntersection.js";
import {Caster} from "./Picker/Caster.js";
import {PointerInput} from "./InputHandler.js";
import {SnapType} from "./common.js";
import {Vector3Const} from "./Helpers/common-utils.js";
import {MeshLine} from "./CustomObjects/MeshLine.js";

export type GeometryObject3D = Mesh | Points | MeshLine;

export interface SelectableModel extends PickableModel {
    readonly isSelectable: true;
    areaPick(caster: Caster, containedOnly?: boolean): Promise<IIntersection>;

    // These methods are only called from Selection.subscribe handler, should never be called directly
    _setSelection(ids: number[]): void;
    _clearSelection(): void;
}

export interface HoverableModel {
    setHoveredFromEvent(event: PointerInput): void;
}

export interface PickableModel {
    pick(caster: Caster): Promise<IIntersection>;
}

export interface SnappedPickableModel extends PickableModel {
    pickSnapped(caster: Caster, snapTypes: SnapType[]): Promise<IIntersection[]>;
}

export abstract class Model {
    get modelId(): string {
        return this.root.name;
    }

    // TODO: make protected
    root = new Object3D();

    protected boundingBox: BehaviorSubject<Box3>;
    transformCallback: () => void;

    constructor(name: string) {
        this.root.name = name;
        this.boundingBox = new BehaviorSubject(new Box3());
        this.root.up.copy(Vector3Const.up);
    }

    getModelBoundingBox(): Box3 {
        return this.boundingBox.value.clone().applyMatrix4(this.root.matrixWorld);
    }

    async getBoundingBox(ids?: number[]): Promise<Box3> {
        return this.getModelBoundingBox();
    }

    subscribeToBoundingBox(callback: () => void): void {
        this.boundingBox.subscribe(() => callback());
    }

    subscribeToTransform(callback: () => void): void {
        this.transformCallback = callback;
    }

    transform(matrix: Matrix4): void {
        if (!matrix.decompose) matrix = new Matrix4().fromArray(matrix.elements);
        matrix.decompose(this.root.position, this.root.quaternion, this.root.scale);
        this.root.updateMatrixWorld(true);
        if (this.transformCallback)
            this.transformCallback();
    }

    isLoading(): boolean {
        return false;
    }

    dispose(): void {}
}
