import {PassComposer} from "../PassComposer.js";
import {DepthTexture, FloatType, RenderTarget, Texture} from "three";
import {Pass} from "three/examples/jsm/postprocessing/Pass.js";
import {WebGPUEdgesAndSsaoRenderPass} from "./WebGPUEdgesAndSsaoRenderPass.js";
import MeshBasicNodeMaterial from "three/examples/jsm/nodes/materials/MeshBasicNodeMaterial.js";
import * as Nodes from "three/examples/jsm/nodes/Nodes.js";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass.js";
import {WebGPUSelectionEffectPass} from "./WebGPUSelectionEffectPass.js";
import {WebGPUBackground} from "./WebGPUBackground.js";
import {Api} from "../../Api.js";
import {RenderingManager} from "../RenderingManager.js";

const trueFn = () => true;

export class WebGPUPassComposer extends PassComposer {
    edgesAndSsaoRenderPass: WebGPUEdgesAndSsaoRenderPass;
    selectionEffectPass: WebGPUSelectionEffectPass;
    background: WebGPUBackground;
    renderTarget: RenderTarget;

    get colorTexture(): Texture {
        return this.renderTarget.texture;
    }

    get normalTexture(): Texture {
        return this.edgesAndSsaoRenderPass.normalTexture;
    }

    override get depthTexture(): DepthTexture {
        return this.renderTarget.depthTexture;
    }

    constructor(api: Api, renderingManager: RenderingManager) {
        super(api, renderingManager);

        this.background = new WebGPUBackground(this.renderingManager, this.api.settingsDispatcher, this.api.camera);
        this.selectionEffectPass = new WebGPUSelectionEffectPass(this.api.camera, this.api.settingsDispatcher.settings);
        this.api.selectionEffectPass = this.selectionEffectPass;

        this.renderTarget = new RenderTarget(this.renderingManager.width, this.renderingManager.height, {
            stencilBuffer: false,
            depthTexture: new DepthTexture(undefined, undefined, FloatType),
        });
        const materialFX = new MeshBasicNodeMaterial();
        const uvNode = Nodes.mx_transform_uv( // Flip Y axis:
            Nodes.vec2(1, -1),
            Nodes.vec2(0, 1),
            Nodes.uv()
        );
        materialFX.colorNode = Nodes.texture(this.renderTarget.texture, uvNode);
        this.copyPass = new ShaderPass(materialFX);
        this.edgesAndSsaoRenderPass = new WebGPUEdgesAndSsaoRenderPass(this.api, this.renderingManager);
    }

    override render(renderMain: boolean = true): void {
        const renderer = this.renderingManager.renderer as any; // WebGPURenderer

        renderer.autoClear = true;
        renderer.autoClearColor = true;
        renderer.autoClearDepth = true;

        if (renderMain) {
            renderer.setRenderTarget(this.renderTarget);

            renderer.setClearColor(0x000000, 0); // TODO: This seems to do nothing (transparent backgrounds will be white, whereas in WebGL they are black)

            this.background.render();

            renderer.autoClear = false;
            renderer.autoClearColor = false;
            renderer.autoClearDepth = false;

            this.edgesAndSsaoRenderPass.render(renderer, this.renderTarget, true, trueFn);

            renderer.autoClear = true;
            renderer.autoClearColor = true;
            renderer.autoClearDepth = true;
            this.selectionEffectPass.render(renderer, this.renderTarget);
            renderer.autoClear = true;
            renderer.autoClearColor = true;
            renderer.autoClearDepth = true;
        }

        this.copyPass.render(renderer, null, this.renderTarget as any, undefined, undefined);
    }

    override setSize(width: number, height: number): void {
        this.renderTarget.setSize(width, height);
        this.edgesAndSsaoRenderPass.setSize(width, height);
        this.selectionEffectPass.setSize(width, height);
    }

    // TODO: Implement:
    addPassBeforeMain(pass: Pass): void {}
    addPassBeforeSelection(pass: Pass): void {}
    addPassAfterSelection(pass: Pass): void {}
    addPassBeforeAntialiasing(pass: Pass): void {}
    addPassAfterAntialiasing(pass: Pass): void {}

    override dispose(): void {
        this.background.dispose();
        this.edgesAndSsaoRenderPass.dispose();
        this.selectionEffectPass.dispose();
        this.copyPass.dispose();
        this.renderTarget.dispose();
    }
}
