import {ToolManager} from "./Tools/ToolManager.js";
import {RenderingManager} from "./Rendering/RenderingManager.js";
import {Picker} from "./Picker/Picker.js";
import {EventDispatcher} from "./EventDispatcher.js";
import {HtmlElementPositioner} from "./HtmlElementPositioner.js";
import {Models} from "./Models.js";
import {Registry} from "./Registry.js";
import {InputHandler} from "./InputHandler.js";

import {Settings, LengthUnit, LoadOptions, QualityPreset, ImageMimeType} from "./common.js";
import {Object3D, Scene} from "three";
import {Api} from "./Api.js";
import {Selection} from "./Selection.js";

import {Web3DCamera} from "./Rendering/Web3DCamera.js";
import {QualityAwarePlugin, Web3DPlugin} from "./Web3DPlugin.js";
import {ApiContainer} from "./ApiContainer.js";
import {XRManager} from "./Rendering/XRManager.js";
import {Model} from "./Model.js";
import {version} from "./Version.js";
import {Cursor3D} from "./Picker/Cursor3D.js";
import {Analytics} from "./Analytics.js";
import {PublicCamera} from "./PublicCamera.js";
import { TextureGenerator } from "./Helpers/TextureGenerator.js";
import {Vector3Const} from "./Helpers/common-utils.js";
import {SettingsDispatcher} from "./SettingsDispatcher.js";

import { Vector3, Quaternion, Matrix4, Euler, Box3, Vector2, Line3, Ray, Triangle, Spherical, Cylindrical, Plane, Frustum, Sphere, Matrix3, Box2, Vector4, Color,
    NoToneMapping, LinearToneMapping, ReinhardToneMapping, CineonToneMapping, ACESFilmicToneMapping,
    LinearSRGBColorSpace, SRGBColorSpace,
} from "three";
import {Tool} from "./Tools/Tool.js";
import {UnsignedByteType} from "three/src/constants.js";
const threeMath =  { Vector3, Quaternion, Matrix4, Euler, Box3, Vector2, Line3, Ray, Triangle, Spherical, Cylindrical, Plane, Frustum, Sphere, Matrix3, Box2, Vector4, Color,
    NoToneMapping, LinearToneMapping, ReinhardToneMapping, CineonToneMapping, ACESFilmicToneMapping,
    LinearSRGBColorSpace, SRGBColorSpace,
};

export class Web3DViewer extends HTMLElement {
    #api: Api;
    #publicCamera: PublicCamera;

    constructor(settings?: Settings) {
        super();
        // tslint:disable-next-line:no-console
        console.log(`web3d build version: ${version}`);

        // Make math classes available in window context for tests and debugging
        Object.assign(window, threeMath);

        // Auto dispose on GC
        const registry = new FinalizationRegistry(() => this.dispose());
        registry.register(this, undefined);

        const settingsDispatcher = new SettingsDispatcher<Settings>(Object.assign({
            background: {topColor: new Color("#FFFFFF"), bottomColor: new Color("#AAAAAA")},
            color: new Color("#000000"),
            lengthUnit: LengthUnit.m,
            decimals: 2,
            backgroundAlpha: 1,
            backgroundRotation: 0,
            darkModeMaterials: false,
            globalOpacity: 1,
            forceDoubleSideMaterials: false,
            vertexInterpolationMaterials: false,
            progressiveRendering: true,
            renderEdges: true,
            debugVerticesColor: new Color("#029be5"),
            ssao: true,
            clippingHighlight: true,
            clippingHighlightColor: new Color("#00F5C4"),
            orderIndependentTransparency: true,
            transparencyPeelsCount: 2,
            hoverHighlightEnabled: false,
            msaa: false,
            fxaa: undefined, // undefined === automatic
            animationTime: 500,
            selectionColor: new Color("#ffcc00"),
            hoverColor: new Color("#0000ff"),
            staticRootUrl: undefined,
            snapDistance: 12,
            navigationSnapDistance: 24,
            analyticsEnabled: false,
            sandbox: false,
        } as Settings, settings || {}));

        this.#initializeApi(settingsDispatcher);
        this.#api.analytics.addViewerErrorStackKeyword(this.constructor.name);
    }

    get settings(): Settings {
        return this.#api.settingsDispatcher.settings;
    }

    applyQualityPreset(quality: QualityPreset): void {
        quality = Math.max(Math.min(quality, QualityPreset.HighQuality), QualityPreset.MaxPerformance);

        if (quality === QualityPreset.HighQuality) {
            this.settings.progressiveRendering = false;
            this.settings.fxaa = true;
            this.settings.renderEdges = true;
            this.settings.ssao = true;
            this.settings.orderIndependentTransparency = true;
            this.settings.transparencyPeelsCount = 4;
            this.settings.vertexInterpolationMaterials = false;
            this.settings.clippingHighlight = true;
        }
        else if (quality === QualityPreset.Optimal) {
            this.settings.progressiveRendering = true;
            this.settings.fxaa = undefined;
            this.settings.renderEdges = true;
            this.settings.ssao = true;
            this.settings.orderIndependentTransparency = true;
            this.settings.transparencyPeelsCount = 2;
            this.settings.vertexInterpolationMaterials = false;
            this.settings.clippingHighlight = true;
        }
        else if (quality === QualityPreset.HighPerformance) {
            this.settings.progressiveRendering = true;
            this.settings.fxaa = undefined;
            this.settings.renderEdges = true;
            this.settings.ssao = true;
            this.settings.orderIndependentTransparency = true;
            this.settings.transparencyPeelsCount = 1;
            this.settings.vertexInterpolationMaterials = false;
            this.settings.clippingHighlight = true;
        }
        else if (quality === QualityPreset.MaxPerformance) {
            this.settings.progressiveRendering = true;
            this.settings.fxaa = false;
            this.settings.renderEdges = false;
            this.settings.ssao = false;
            this.settings.orderIndependentTransparency = false;
            this.settings.vertexInterpolationMaterials = true;
            this.settings.clippingHighlight = false;
        }

        for (const p in this.plugins) {
            const plugin = this.plugins[p] as any as QualityAwarePlugin;
            if (plugin.applyQualityPreset) plugin.applyQualityPreset(quality);
        }
    }

    isCubeBackground(): boolean {
        return this.#api.renderingManager.composer.isCubeBackground();
    }

    set renderingEnabled(enabled: boolean) {
        this.#api.renderingManager.enabled = enabled;
    }

    get renderingEnabled(): boolean {
        return this.#api.renderingManager.enabled;
    }

    addPlugin(p: Web3DPlugin): Web3DViewer {
        const plugin = p as Web3DPlugin;
        plugin.api = this.#api;
        if (this.plugins[plugin.name]) throw Error(`Plugin ${plugin.name} is already initialized`);
        if (plugin.version !== version) throw Error(`Plugin version mismatch ${plugin.version}`);
        this.plugins[plugin.name] = plugin;

        this.#api.analytics.addViewerErrorStackKeyword(plugin.constructor.name);
        return this;
    }

    async load(ref: any, options: LoadOptions = {}): Promise<Model> {
        options.fitToView = options.fitToView ?? true;

        const loader = Array.from(this.#api.registry.LUT.entries()).find(pair => pair[0](ref, options));
        if (!loader)
            throw new Error(`Unknown model type: ${ref}`);

        const model = await loader[1](ref, options);

        if (options.transform) model.transform(options.transform);
        if (options.fitToView) {
            const box = this.#api.models.worldBoundingBox.value;
            this.#api.camera.fitToView(box, this.#api.settingsDispatcher.settings.animationTime);
        }

        this.#api.renderingManager.redraw();
        this.#api.analytics.logLoadEvent(model.modelId);
        return model;
    }

    async unload(modelId: string, options?: LoadOptions): Promise<void> {
        options = options || { fitToView: true };
        modelId = options.modelId || modelId;

        await this.#api.models.remove(modelId);
        if (options.fitToView)
            this.#api.camera.fitToView(this.#api.models.worldBoundingBox.value, this.#api.settingsDispatcher.settings.animationTime);
        else
            this.#api.renderingManager.redraw();
    }

    getModel<T extends Model>(modelId: string, type?: new (...params: any) => T): T | undefined {
        const model = this.#api.models.get(modelId);
        return type === undefined || model instanceof type ? model as T : undefined;
    }

    getModels<TT extends Model>(type?: new (...params: any) => TT): TT[] {
        return this.#api.models.getModels(type);
    }

    #initializeApi(settingsDispatcher: SettingsDispatcher<Settings>): void {
        const api: Api = {};
        this.#api = api;
        api.registry = new Registry();
        Object3D.DEFAULT_UP.copy(Vector3Const.up);

        // @ts-ignore
        api.staticRootUrl = settingsDispatcher.settings.staticRootUrl || window.DEFAULT_STATIC_ROOT_URL || "/"; // will be replaced with import.meta.url by rollup

        api.container = document.createElement("main");
        api.container.setAttribute("id", "content");
        api.container.appendChild(document.createElement("canvas"));

        api.eventDispatcher = new EventDispatcher(this);
        api.settingsDispatcher = settingsDispatcher;
        api.scene = new Scene();

        api.selection = new Selection();
        api.selection.startSelectionEventEmitter(api.eventDispatcher);
        api.models = new Models(api.scene, api.selection);
        api.plugins = new ApiContainer<Web3DPlugin>();

        api.camera = new Web3DCamera(this.#api);

        api.renderingManager = new RenderingManager(api);
        api.picker = new Picker(api.camera, api.models, api.container, api.settingsDispatcher, api.renderingManager);
        api.htmlElementPositioner = new HtmlElementPositioner(api);

        api.inputHandler = new InputHandler(
            api.picker,
            api.container
        );

        this.#publicCamera = new PublicCamera(api.camera, api.models, api.settingsDispatcher);
        api.cursor = new Cursor3D(api);

        api.toolManager = new ToolManager(api, this.#publicCamera);

        api.models.worldBoundingBox.subscribe(() => api.renderingManager.redraw());
        api.models.worldBoundingBox.subscribe(aabb => api.picker.setWorldBoundingBox(aabb));
        api.analytics = new Analytics(api.settingsDispatcher);

        api.textureGenerator = new TextureGenerator();

        this.#createShadowDom();
        this.#api.renderingManager.start();
    }

    #createShadowDom(): void {
        this.attachShadow({mode: 'open'});
        // noinspection CssInvalidPropertyValue
        this.shadowRoot.innerHTML += `
            <style>
                :host {
                    display: block;
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                }

                main {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    cursor: default;
                    overflow: hidden;
                    position: relative;
                }

                canvas {
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: relative;
                }

                .grab {
                    cursor: -webkit-grab !important;
                    cursor: -moz-grab !important;
                    cursor: grab !important;
                }

                .grabbing {
                    cursor: -webkit-grabbing !important;
                    cursor: -moz-grabbing !important;
                    cursor: grabbing !important;
                }

                .default {
                    cursor: default;
                }
            </style>`;
        this.shadowRoot.appendChild(this.#api.container);
    }

    set activeTool(tool: string) {
        this.#api.toolManager.activeTool = tool;
    }

    get activeTool(): string {
        return this.#api.toolManager.activeTool;
    }

    get tools(): ApiContainer<Tool> {
        return this.#api.toolManager.tools;
    }

    get plugins(): ApiContainer<Web3DPlugin> {
        return this.#api.plugins;
    }

    async screenshot(size?: Vector2, type?: ImageMimeType, quality?: number): Promise<Blob> {
        return this.#api.renderingManager.screenshot(size, type, quality);
    }

    readColorBuffer(): Uint8Array {
        return this.#api.renderingManager.readColorBuffer();
    }

    readNormalBuffer(): Float32Array {
        return this.#api.renderingManager.readNormalBuffer();
    }

    readDepthBuffer(): Float32Array {
        return this.#api.renderingManager.readDepthBuffer();
    }

    get selection(): Selection {
        return this.#api.selection;
    }

    get camera(): PublicCamera {
        return this.#publicCamera;
    }

    get width(): number {
        return this.#api.renderingManager.width;
    }

    get height(): number {
        return this.#api.renderingManager.height;
    }

    get xr(): XRManager {
        return this.#api.renderingManager.xr;
    }

    waitForRender(): Promise<void> {
        this.#api.renderingManager.redraw();
        return this.#api.renderingManager.waitForRender();
    }

    dispose(): void {
        this.#api.renderingManager.enabled = false; // stop rendering loop
        this.#api.cursor.unsubscribe();
        for (const m of this.#api.models.getModels())
            m.dispose();
        this.#api.renderingManager.renderer.dispose();
        this.#api.renderingManager.composer.dispose();
        this.#api.renderingManager.renderer.forceContextLoss();
        this.#api.inputHandler.dispose();
        this.#api.picker.dispose();
        if (this.parentElement)
            this.parentElement.removeChild(this);
        this.#api.analytics.dispose();
    }
}

// Register custom element, append number suffix if name is already taken by another version of web3d
let suffix = "";
while (true) {
    const elConstructor = customElements.get("web3d-viewer" + suffix);
    if (!elConstructor) {
        customElements.define("web3d-viewer" + suffix, Web3DViewer);
        break;
    }
    if (elConstructor === Web3DViewer)
        break;
    suffix = String(Number(suffix) + 1);
}
