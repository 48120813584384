import {
    Camera,
    FloatType,
    OrthographicCamera,
    Vector2,
    Vector3,
    LinearMipmapLinearFilter,
    RenderTarget,
} from "three";
import { SettingsDispatcher } from "../SettingsDispatcher.js";
import { Settings } from "../common.js";
import { RenderingManager } from "../Rendering/RenderingManager.js";

export abstract class GPUPicker {
    protected readonly pickCamera = new OrthographicCamera(-1, 1, 1, -1, -1, 1);
    protected readonly singlePixelRT = new RenderTarget(1, 1, {type: FloatType});
    protected readonly smallRT = new RenderTarget(5, 5, {type: FloatType});
    protected intervalId: any;
    protected depths = new Float32Array(this.smallRT.width * this.smallRT.height * 4);
    protected readonly depthCopyRT = new RenderTarget(1, 1, {
        type: FloatType,
        generateMipmaps: true, // efficient downscaling to single pixel
        minFilter: LinearMipmapLinearFilter
    });

    protected constructor(
        protected renderingManager: RenderingManager,
        protected settingsDispatcher: SettingsDispatcher<Settings>,
    ) {
        // TODO: Do this also for WebGPU?
        // check screen size change periodically, as resize and shader compilation takes time, ideally we do it while user is idle
        this.intervalId = setInterval(() => {
            if (this.updateRenderTargetSize() && !this.settingsDispatcher.settings.useWebgpu)
                void this.pickDepth(new Vector2(), new Vector2(), new Vector2()); // force recompile shaders
        }, 1000);
    }

    /**
     * Pick the GPU depth buffer.
     * @param screenPosition The 2D screen coordinate to be picked
     * @returns A 3D vector representing the world space point of the pick
     */
    abstract pick(screenPosition: Vector2): Promise<Vector3 | undefined>;

    protected abstract pickDepth(viewPosition: Vector2, tolerance1: Vector2, tolerance2: Vector2): Promise<number | undefined>;

    protected updateRenderTargetSize(): boolean {
        const depthTexture = this.renderingManager.composer.depthTexture;
        if (this.depthCopyRT.width !== depthTexture.image.width && depthTexture.image.width !== undefined ||
            this.depthCopyRT.height !== depthTexture.image.height && depthTexture.image.height !== undefined) {
            this.depthCopyRT.setSize(depthTexture.image.width, depthTexture.image.height);
            return true;
        }
        return false;
    }

    dispose(): void {
        clearInterval(this.intervalId);
    }

    /**
     * Returns the world space point given a view coordinate and a depth sample (as read
     * from the depth buffer).
     */
    protected static viewToWorldPoint = (() => {
        const pos = new Vector3();  // Global position

        return (viewPosition: Vector2, depthSample: number, camera: Camera): Vector3 => {
            pos.set(viewPosition.x, 1.0 - viewPosition.y, depthSample)
                .subScalar(0.5)                                  // [0,1]^3 -> [-0.5, 0.5]^3
                .multiplyScalar(2)                               // -> [-1,1]^3
                .applyMatrix4(camera.projectionMatrixInverse)       // Camera relative position
                .applyMatrix4(camera.matrixWorld);               // World position
            return pos.clone();
        };
    })();
}

