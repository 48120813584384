import "./Rendering/WebGPU/FixWebGPU.js";
export * from "./common.js";
export * from "./Picker/IIntersection.js";
export * from "./Web3DViewer.js";
export * from "./Web3DPlugin.js";
export * from "./Selection.js";
export * from "./Tools/Tool.js";
export * from "./Tools/Fly.js";
export * from "./Tools/Orbit.js";
export * from "./Tools/Pan.js";
export * from "./Tools/Zoom.js";
export * from "./Tools/Walk.js";
export * from "./Tools/FPSNavigation.js";
export * from "./Tools/PanoramaControls.js";
export * from "./Tools/SelectionTool.js";
export * from "./Tools/PickingTool.js";
export * from "./Tools/AreaSelectionTool.js";
export * from "./Tools/XRNavigation.js";
export * from "./Tools/GamepadNavigation.js";
export * from "./Tools/CameraShortcuts.js";
export * from "./PublicCamera.js";
export * from "./Rendering/XRManager.js";
export * from "./Model.js";
export * from "./ApiContainer.js";

export { Vector3, Quaternion, Matrix4, Euler, Box3, Vector2, Line3, Ray, Triangle, Spherical, Cylindrical, Plane, Frustum, Sphere, Matrix3, Box2, Vector4, Color,
    NoToneMapping, LinearToneMapping, ReinhardToneMapping, CineonToneMapping, ACESFilmicToneMapping,
    LinearSRGBColorSpace, SRGBColorSpace
} from "three";

import { ColorManagement } from "three";
export { ColorManagement };

// Turning colour management off to maintain consistency. But for future consideration:
// https://discourse.threejs.org/t/updates-to-color-management-in-three-js-r152/50791
ColorManagement.enabled = false;
