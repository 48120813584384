import {MathUtils, Vector3} from "three";
import {TypedRanges} from "../Rendering/DisplayGroup.js";

/**
 * A utils file common for main thread and workers.
 * NB: Be careful what you import here, as it will be added to the web worker bundle.
 * In particular, do not use THREE.js here.
 */

export type TypedArray =
    | Uint8Array
    | Uint8ClampedArray
    | Uint16Array
    | Uint32Array
    | Int8Array
    | Int16Array
    | Int32Array
    | Float32Array
    | Float64Array;

// max size of buffer which can be indexed by 16bit integer
export const MAX_16BIT_SIZE = 0x10000;

export function getPerspectiveViewWorldSize(fov: number, distance: number): number {
    return Math.tan(fov * 0.5 * MathUtils.DEG2RAD) * distance;
}

export function iterate<T>(array: T[] | T, loop: (o: T, i: number) => void): void {
    if (Array.isArray(array)) {
        for (let i = 0; i < array.length; i++)
            loop(array[i], i);
    }
    else {
        loop(array, 0);
    }
}

// Remove image from RAM after they are transfered to VRAM
export function disposeImage(): void {
    // @ts-ignore
    this.image = undefined;
}

export class Vector3Const {
    static zero = Object.freeze(new Vector3(0, 0, 0));
    static one = Object.freeze(new Vector3(1, 1, 1));

    static up = Object.freeze(new Vector3(0, 0, 1));
    static down = Object.freeze(new Vector3(0, 0, -1));
    static forward = Object.freeze(new Vector3(0, 1, 0));
    static back = Object.freeze(new Vector3(0, -1, 0));
    static right = Object.freeze(new Vector3(1, 0, 0));
    static left = Object.freeze(new Vector3(-1, 0, 0));

    // Original threejs math is Y-up based
    static threejsUp = Object.freeze(new Vector3(0, 1, 0));
    static threejsDown = Object.freeze(new Vector3(0, -1, 0));
    static threejsForward = Object.freeze(new Vector3(0, 0, 1));
    static threejsBack = Object.freeze(new Vector3(0, 0, -1));
    // For historical reasons threejs camera is looking back
    static threejsCameraForward = Object.freeze(new Vector3(0, 0, -1));
    static threejsCameraBack = Object.freeze(new Vector3(0, 0, 1));
}

/**
 * Calls a function on each range of the given TypedRanges object, if it exists. Treats count=0xffffffff as Infinity.
 * @param ranges A TypedRanges object
 * @param fn A function that is called for each range, i.e., (start, count) pair
 */
export function iterateTypedRanges(ranges: TypedRanges, fn: (start: number, count: number) => void): void {
    if (!ranges || ranges.starts.length === 0) return;
    for (let i=0; i<ranges.starts.length; ++i)
        fn(ranges.starts[i], ranges.counts[i] === 0xffffffff ? Infinity : ranges.counts[i]);
}
