import Renderer from 'three/examples/jsm/renderers/common/Renderer.js';
import WebGPUBackend from './WebGPUBackend.js';
import {StringReplacer} from "./StringReplacer.js";

class WebGPURenderer extends Renderer {

    constructor( parameters = {} ) {

        let BackendClass = WebGPUBackend;
        const backend = new BackendClass( parameters );

        //super( new Proxy( backend, debugHandler ) );
        super( backend );

        this.isWebGPURenderer = true;



        // Overriding createProgram() method to do string replacement on generated shader code.
        // This is meant as a temporary solution to support WebGPU features not yet supported by Three.js' node system.
        // See this discussion: https://discourse.threejs.org/t/does-the-current-nodes-system-support-writing-to-depth-buffer-in-webgpu/58463
        const replacer = new StringReplacer();
        replacer.add(edgePassCondition, edgePassKey1, edgePassValue1);
        replacer.add(edgePassCondition, edgePassKey2, edgePassValue2);
        replacer.add(`// Background vertex shader`, `NodeVaryings.Vertex = nodeVar0;`, `NodeVaryings.Vertex = vec4(nodeVarying0, 1.0);`);

        backend.createProgram_original = backend.createProgram;
        backend.createProgram = (program) => {
            program.code = replacer.replace(program.code);
            //console.log(program.code);
            backend.createProgram_original(program);
        }

    }
}

// Replacement strings:

const edgePassCondition = `var<private> fragDepthPlaceholder`;
const edgePassKey1 = `struct StructType0 {
\t@location( 0 ) m0 : vec4<f32>
}`;
const edgePassValue1 = `struct StructType0 {
\t@location( 0 ) m0 : vec4<f32>,
\t@builtin(frag_depth) depth: f32
}`;
const edgePassKey2 = `return nodeVar3;`;
const edgePassValue2 = `
nodeVar3.depth = fragDepthPlaceholder;
return nodeVar3;`;



export default WebGPURenderer;