import {PointerInput} from "../InputHandler.js";
import {Api} from "../Api.js";
import {
    BufferAttribute,
    BufferGeometry,
    DoubleSide,
    Group,
    MeshBasicMaterial,
    Object3D,
    Points,
    RingGeometry,
    Scene,
    Vector2,
    Vector3
} from "three";
import {Subscription} from "rxjs";
import {IIntersection} from "./IIntersection.js";
import {SnapType, Web3DEventName} from "../common.js";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial.js";
import {MeshLine} from "../CustomObjects/MeshLine.js";
import {createPointIconMaterial, createScreenStaticSizeMesh, loadPointIconTexture} from "../Helpers/utils.js";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass.js";
import {MeshLineGeometry} from "../CustomObjects/MeshLineGeometry.js";

export class Cursor3D {
    private _moveSubscription: Subscription;
    private intervalId: any;
    private faceCursor: Object3D;
    private pointCursor: Points;
    private centerCursor: Points;
    private lineCursor: Points;
    private lineHighlight: MeshLine;
    private cursor: Object3D;
    private renderPass: RenderPass;
    private isNavigating: boolean;

    snapTypes: SnapType[] = [SnapType.CENTER_POINT, SnapType.CENTER_LINE, SnapType.POINT, SnapType.LINE, SnapType.FACE];
    intersection: IIntersection;

    private normal = new Vector3();

    private createCursors(): void {
        const pointsGeometry = new BufferGeometry();
        pointsGeometry.setAttribute("position", new BufferAttribute(new Float32Array([0, 0, 0]), 3));

        // Surface
        const markerMaterial = createPointIconMaterial(loadPointIconTexture(this._api.staticRootUrl + "images/snap_face.png"), 15);
        this.faceCursor = new Group();
        const points = new Points(pointsGeometry, markerMaterial);
        points.renderOrder = 1;
        this.faceCursor.add(points);
        const material = new MeshBasicMaterial({color: 0xffffff, depthTest: false, depthWrite: false, transparent: true, opacity: 0.3, side: DoubleSide});
        const ringGeometry = new RingGeometry(2, 2.25, 4);
        ringGeometry.translate(0, 0, 0.01);
        ringGeometry.rotateZ(Math.PI / 4);
        const ringMesh = createScreenStaticSizeMesh(ringGeometry, material, this._api);
        this.faceCursor.add(ringMesh);
        this.faceCursor.renderOrder = 1;

        // Point
        this.pointCursor = new Points(pointsGeometry,
            createPointIconMaterial(loadPointIconTexture(this._api.staticRootUrl + "images/snap_point.png"), 15));

        // Center point
        this.centerCursor = new Points(pointsGeometry,
            createPointIconMaterial(loadPointIconTexture(this._api.staticRootUrl + "images/snap_midpoint.png"), 15));


        // Line
        this.lineCursor = new Points(pointsGeometry,
            createPointIconMaterial(loadPointIconTexture(this._api.staticRootUrl + "images/snap_edge.png"), 15));
        this.lineHighlight = new MeshLine(new MeshLineGeometry(), new LineMaterial({linewidth: 3, color: 0xffffff, opacity: 0.3, depthTest: false, depthWrite: false, transparent: true}));
    }

    private createRenderPass(): void {
        this.renderPass = new RenderPass(new Scene(), this._api.camera);
        this.renderPass.clear = false;
        this._api.renderingManager.composer.addPassAfterAntialiasing(this.renderPass);
    }

    constructor(private _api: Api) {
        this.createCursors();
        this.createRenderPass();
        this.cursor = this.faceCursor;
        this.initNavigationIntegration();
    }

    private initNavigationIntegration(): void {
        this._api.eventDispatcher.subscribe(Web3DEventName.NavigationStart, () => {
            this.cursor.visible = false;
            this.lineHighlight.visible = false;
            this.isNavigating = true;
        });
        this._api.eventDispatcher.subscribe(Web3DEventName.NavigationEnd, () => {
            this.isNavigating = false;
        });
    }

    public subscribe(onMove?: () => void): void {
        let lastEvent: PointerInput;
        let lastCalculatedEvent: PointerInput;
        let lastEventTime: number = 0;

        this._moveSubscription = this._api.inputHandler.pointerMove$
            .subscribe(async e => {
                if (!this.isNavigating) {
                    lastEvent = e;
                    lastEventTime = performance.now();
                    // avoid flooding trimbim worker message queue
                    if (!this._api.picker.pickerBusy) {
                        lastCalculatedEvent = e;
                        await this.calculateIntersection(e);
                        if (onMove) onMove();
                    }
                }
            });

        this.intervalId = setInterval(async () => {
            // if picker was busy during last move event, recalculate
            if (!this.isNavigating && lastCalculatedEvent !== lastEvent && performance.now() > lastEventTime + 200) {
                lastCalculatedEvent = lastEvent;
                await this.calculateIntersection(lastEvent);
                if (onMove) onMove();
            }
        }, 200);

        this.faceCursor.visible = false;
        this.renderPass.scene.add(this.faceCursor);

        this.pointCursor.visible = false;
        this.renderPass.scene.add(this.pointCursor);

        this.centerCursor.visible = false;
        this.renderPass.scene.add(this.centerCursor);

        this.lineCursor.visible = false;
        this.renderPass.scene.add(this.lineCursor);
        this.lineHighlight.visible = false;
        this.renderPass.scene.add(this.lineHighlight);

        this._api.renderingManager.redraw();
    }

    public unsubscribe(): void {
        if (!this._moveSubscription) return;

        this._moveSubscription.unsubscribe();
        this._moveSubscription = undefined;
        clearInterval(this.intervalId);
        this.renderPass.scene.remove(this.faceCursor);
        this.renderPass.scene.remove(this.pointCursor);
        this.renderPass.scene.remove(this.centerCursor);
        this.renderPass.scene.remove(this.lineCursor);
        this.renderPass.scene.remove(this.lineHighlight);
        this._api.renderingManager.redraw();
    }

    async calculateIntersection(event: PointerInput): Promise<PointerInput> {
        event.intersection = await this._api.picker.pickSnapped(new Vector2(event.x, event.y), this.snapTypes);
        this.intersection = event.intersection;
        const intersection = this.intersection;

        this.cursor.visible = false;
        if (intersection) {
            switch(intersection.snapType) {
                case SnapType.CENTER_POINT:
                case SnapType.CENTER_LINE:
                case SnapType.CONTROL_POINT:
                case SnapType.MIDPOINT:
                case SnapType.PERPENDICUAR_POINT:
                case SnapType.INTERSECTION_POINT:
                    this.cursor = this.centerCursor;
                    break;
                case SnapType.LINE:
                    this.cursor = this.lineCursor;
                    break;
                case SnapType.FACE:
                    this.cursor = this.faceCursor;
                    break;
                case SnapType.POINT:
                    this.cursor = this.pointCursor;
                    break;
            }

            this.cursor.position.copy(intersection.point);

            if (intersection.normal) {
                this.normal.copy(this._api.camera.position).sub(intersection.point);
                const dot = this.normal.dot(intersection.normal);
                this.normal.copy(intersection.normal);
                if (dot < 0) this.normal.negate();
                this.cursor.lookAt(this.normal.clone().add(intersection.point));
            }

            this.lineHighlight.visible = !!intersection.snapLineStart && !this.isNavigating;
            if (intersection.snapLineStart) {
                this.lineHighlight.position.copy(intersection.snapLineStart);
                this.lineHighlight.update([new Vector3(), intersection.snapLineEnd.clone().sub(intersection.snapLineStart)]);
            }

            this.cursor.updateMatrix();
            this.cursor.visible = !this.isNavigating;
        }

        this._api.renderingManager.redraw(false);

        return event;
    }
}
