import {Background} from "../Background.js";
import {RenderingManager} from "../RenderingManager.js";
import {BackgroundGradient, Settings} from "../../common.js";
import {Web3DCamera} from "../Web3DCamera.js";
import {SettingsDispatcher} from "../../SettingsDispatcher.js";
import {
    FrontSide,
    Mesh,
    PlaneGeometry, ShaderLib,
    Texture,
    WebGPUCoordinateSystem
} from "three";
import MeshBasicNodeMaterial from "three/examples/jsm/nodes/materials/MeshBasicNodeMaterial.js";
import * as Nodes from "three/examples/jsm/nodes/Nodes.js";

export class WebGPUBackground extends Background {

    constructor(renderingManager: RenderingManager, settingsDispatcher: SettingsDispatcher<Settings>, camera: Web3DCamera) {
        super(renderingManager, settingsDispatcher, camera);
        this.camera.coordinateSystem = WebGPUCoordinateSystem;
    }

    renderColor(): void {
        // The single-colour case is captured by the update() method and turned into a gradient
    }

    renderTexture(): void {
        const background = this.background as Texture;

        if (this.planeMesh === undefined) {
            const planeGeometry = new PlaneGeometry(2, 2);
            this.planeMesh = new Mesh(planeGeometry, this.createPlaneMaterial());
            planeGeometry.deleteAttribute('normal');

            this.backgroundScene.children.length = 0;
            this.backgroundScene.add(this.planeMesh);
        }

        if (this.boxMesh) {
            this.backgroundScene.remove(this.boxMesh);
            this.boxMesh = undefined;
        }

        if (background.matrixAutoUpdate === true) {
            // @ts-ignore
            this.background.updateMatrix();
        }

        // @ts-ignore
        this.planeMesh.material.uniforms.uvTransform.node.value.copy(this.background.matrix);

        if (this.currentBackground !== this.background ||
            this.currentBackgroundVersion !== background.version) {

            // @ts-ignore
            this.planeMesh.material.colorNode.parameters.t2D = Nodes.texture(this.background);
            // @ts-ignore
            this.planeMesh.material.colorNode.parameters.t2D_sampler = Nodes.texture(this.background);

            // @ts-ignore
            this.planeMesh.material.needsUpdate = true;

            this.currentBackground = this.background;
            this.currentBackgroundVersion = background.version;
        }

        this.syncCamera();
        this.renderingManager.renderer.render(this.backgroundScene, this.backgroundCamera);
    }

    renderCubemap(): void {
        throw new Error("Not implemented");
    }

    override update(): void {
        // Capture single-colour background and turn it into a trivial gradient.
        // TODO: Render single-colour backgrounds by setting clear colour and clearing the render target.
        // Renderer.setClearColour() is not supported by Three.js for WebGPU yet.
        super.update();
        const color = this.toColor(this.settingsDispatcher.settings.background);
        if (color) this.background = this.toGradient({ topColor: color, bottomColor: color } as BackgroundGradient);
    }

    private createPlaneMaterial(): MeshBasicNodeMaterial {
        const planeMaterial = new MeshBasicNodeMaterial();

        const uniforms = [
            // @ts-ignore
            Nodes.uniform(Nodes.mat3(ShaderLib.background.uniforms.uvTransform.value)).label('uvTransform'),
        ];

        planeMaterial.uniforms.uvTransform = uniforms[0];

        // @ts-ignore
        const colorNode = Nodes.wgslFn(`
            fn getColor(uv: vec2<f32>, t2D: texture_2d<f32>, t2D_sampler: sampler) -> vec4<f32> {
                var transformedUV: vec2<f32> = (NodeUniforms.uvTransform * vec3(uv, 1.0)).xy;
                return textureSample(t2D, t2D_sampler, transformedUV);
            }
        `, uniforms);

        const params = {
            position: Nodes.attribute('position', 'vec3'),
            uv: Nodes.uv(),
            t2D: Nodes.texture(undefined),
            t2D_sampler: Nodes.texture(undefined),
        };

        // @ts-ignore
        planeMaterial.positionNode = Nodes.wgslFn(`
            fn getPosition(position: vec3<f32>) -> vec4<f32> {
                return vec4(position.xy, 1.0, 1.0);
            }
            // Background vertex shader
        `)(params);

        // NB: The position node output is currently transformed in the final shader code generated by Three.js,
        // outside of the programmer's control. For now, this is fixed by a string replacement hack done in
        // WebGPURenderer.js. TODO: Do this properly once supported by Three.js, or find a different solution.
        // See discussion: https://discourse.threejs.org/t/custom-wgsl-position-node-in-clip-space-webgpu/59223

        planeMaterial.colorNode = colorNode(params);

        planeMaterial.side = FrontSide;
        planeMaterial.depthTest = false;
        planeMaterial.depthWrite = false;
        planeMaterial.fog = false;
        planeMaterial.dithering = true;

        return planeMaterial;
    }

}
