import { Api } from "../Api.js";
import {Vector3, Matrix4, Scene, Quaternion, Camera} from "three";
import {Tool} from "./Tool.js";

/**
 * Simple navigation in VR, based on WebXR.
 * NB: Experimental.
 */
export class XRNavigation extends Tool {
    static get Name(): string { return "xrNavigation"; }

    private _enabled: boolean;
    private _api: Api;
    private _scene: Scene;
    private _speed: number = 0;
    private _refOrientation: Quaternion;
    private _refMatrix: Matrix4;
    private _artificialRotationInProgress: boolean = false;
    private _artificialRotation: number = 0;
    private _accelerating: boolean = false;
    private vrCam: Camera;

    private readonly BASE_SPEED = 0.015;
    private readonly ACC = 50e-6;
    private readonly ARTIFICIAL_ROT_ANGLE = Math.PI/8;
    private readonly ACCELERATION_THRESHOLD = 0.75;
    private readonly INSTANT_BREAK_THRESHOLD = 0.05;

    get name(): string { return XRNavigation.Name; }

    get enabled(): boolean { return this._enabled; }

    set enabled(value: boolean) {
        if (this._enabled === value) return;
        this._enabled = value;
        if (this._enabled) this._api.renderingManager.addAnimationFrameListener(this.update);
        else this._api.renderingManager.removeAnimationFrameListener(this.update);
    }

    private get _session(): any {
        return this._api.renderingManager.renderer.xr.getSession();
    }

    constructor(api: Api) {
        super();
        this._api = api;
        this._scene = api.scene;

        this._refOrientation = new Quaternion();
        this.vrCam = this._api.renderingManager.xr.toVRCamera(api.camera);
        this.vrCam.getWorldQuaternion(this._refOrientation);
        this._refMatrix = this.vrCam.matrixWorld.clone();
    }

    update = (() => {
        const that = this;
        let previousTimestamp = 0;
        let delta = 0;
        return (timestamp: number) => {
            delta = timestamp - previousTimestamp;
            previousTimestamp = timestamp;
            if (that.enabled) that.move(delta);
        };
    })();

    private static hapticsPulse(sources: any[], intensity: number = 0.5, duration: number = 100): void {
        // TODO: Check/wait for support for this
        for (const source of sources) {
            if (!source.pad) continue;
            const pad = source.pad;
            if (pad && pad.hapticActuators &&
                pad.hapticActuators.length > 0 && pad.hapticActuators[0] &&
                pad.hapticActuators[0].pulse
            ) pad.hapticActuators[0].pulse(intensity, duration);
        }
    }

    private move(delta: number): void {
        if (!this._session) return;
        const sources = this._session.inputSources;
        if (!sources) return;

        const cameraRig = this._api.renderingManager.xr.cameraRig;
        if (!cameraRig) return;

        let axisSumX = 0.0;
        let axisSumY = 0.0;

        // https://www.w3.org/TR/webxr-gamepads-module-1/
        for (const source of sources) {
            const axes = source.gamepad ? source.gamepad.axes : undefined;
            if (axes && axes.length === 4) {
                axisSumX += axes[0];
                axisSumY -= axes[1];
                axisSumX += axes[2];
                axisSumY -= axes[3];
            }
        }

        const camOrientation = new Quaternion();

        this.vrCam = this._api.camera;
        this.vrCam.getWorldQuaternion(camOrientation);

        const camDir = new Vector3(0,0,-1)
            .applyQuaternion(camOrientation);

        // Instant breaking
        if (this._speed > 0 && axisSumY < this.INSTANT_BREAK_THRESHOLD || this._speed < 0 && axisSumY > -this.INSTANT_BREAK_THRESHOLD)
            this._speed = 0;

        // Accelerating
        if (Math.abs(axisSumY) > this.ACCELERATION_THRESHOLD) {
            this._speed += axisSumY * Math.abs(Math.pow(axisSumY, 2)) * this.ACC * delta;
            this._accelerating = true;
        } else {
            this._speed *= Math.pow(Math.abs(axisSumY), 0.01 * delta);
            this._accelerating = false;
        }

        // Navigation:
        const baseSpeed = this.BASE_SPEED * axisSumY * Math.min(axisSumY*axisSumY, 0.2);
        const dTot = (baseSpeed + this._speed) * delta;
        cameraRig.position.add(camDir.clone().multiplyScalar(dTot));

        // Artificial rotation:
        let artificialRotationDelta = 0.0;
        if (Math.abs(axisSumY) < 0.5 && Math.abs(axisSumX) > 0.75) {
            if (!this._artificialRotationInProgress) {
                XRNavigation.hapticsPulse(sources, 0.5, 100);
                this._artificialRotationInProgress = true;
                artificialRotationDelta -= Math.sign(axisSumX) * this.ARTIFICIAL_ROT_ANGLE;
            }
        }
        if (Math.abs(axisSumX) < 0.7) {
            this._artificialRotationInProgress = false;
        }
        this._artificialRotation += artificialRotationDelta;
        const newCamDir = new Vector3();
        cameraRig.getWorldDirection(newCamDir);
        newCamDir.applyAxisAngle(new Vector3(0,0,1), artificialRotationDelta);
        const camPos = new Vector3();
        cameraRig.getWorldPosition(camPos);
        cameraRig.lookAt(camPos.clone().add(newCamDir));
    }
}
